import "./Homepage.css";

export default function Homepage() {
    return (
        <div className="Homepage">
            <div className="Homepage-Container">
                <img className="Homepage-Banner" src="/banner.png" alt="SpyCheck"/>

                <b style={{color: "#f44"}}>Important!</b>
                <p>
                    This game is currently under development. Things will be unfinished, broken, or otherwise not fully polished, and anything is subject to change.
                    If you have feedback or think you have found an issue with the game, please contact me on Discord: <code>Hacktix™#3264</code>
                </p>
                <br/><br/>

                <h1>What's this?</h1>
                <p>SpyCheck is a social deduction game for 3 or more players in which the players have to find the spy among them based on answers to various questions.</p>

                <h1>How does the game work?</h1>
                <h2>Requirements</h2>
                <p>You will need to be able to communicate with the other players, either via call or by being in the same room. Each player needs a phone, tablet or computer with access to this website.</p>
                <h2>The Game</h2>
                <p>At the beginning of the game, one player is chosen to be <b style={{color: "#f44"}}>The Spy</b>. The objective of the non-spy players is to find and eliminate the spy, while the spy has to try to not be caught.</p>
                <p>One game can take up to three rounds. At the beginning of a round the players are asked a multiple-choice question, which they can select an answer to. The Spy, however, only sees the answer options, not the question.</p>
                <p>After everyone, including the spy, has selected an answer, it is time to discuss the answers. The spy is now also shown what the original question was, and has to justify their answer to not seem suspicious.</p>
                <p>During discussion time, each player gets one vote to put on other players, or the option to Skip Vote. The player who is voted most often is eliminated and cannot participate in any following rounds.</p>
                <p>If the spy manages to stay undetected and not get eliminated for three rounds, the spy wins. If the spy is eliminated at any point, all other players immediately win.</p>

                <h1>Credits</h1>
                <p>This game is based on the VRChat World "Spy Check?!" by Jar. All development on this web version was done by Hacktix.</p>
            </div>
        </div>
    );
}